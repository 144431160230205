 .<template>
      
  <div class="all">
    <div class="header">
      <img :src="img" alt="" class="header" />
    </div>
    <!-- <aside class="action">
      首页 &nbsp;
      <img src="../assets/img/InforCenter/juxing14.png" alt="" />
      &nbsp;资讯中心 &nbsp;<img
        src="../assets/img/InforCenter/juxing14.png"
        alt=""
      />
      &nbsp;通知公告
    </aside> -->
    <mainBox></mainBox>

    <div class="details" >
      <div class="titles">志愿招募丨顺义区博物馆志愿者招募公告!</div>
      <div class="DetailFont">
        顺义博物馆是顺义区首家区级博物馆，馆内总建筑面积7656平方米，馆内设有大型下沉式多媒体沙盘、文创产品区、互动区、历史文化展厅、临展厅、多媒体功能厅、文物库房等。为更好地服务社会大众，进一步弘扬“奉献、友爱、互助、进步”的志愿者精神，顺义区博物馆面向社会招募志愿者。大家可根据自己的特长与爱好选择志愿服务岗位，顺义博物馆期待您的加入！
      </div>
      <div class="DetailImg">
        <img src="../assets/volunteer.png" alt="" />
      </div>
      <div class="liebiao">
        <div class="titleImgRed">
          <span class="img">
            <img src="../assets/header.png" alt="" class="miao" />
            招募类型和工作内容
          </span>
        </div>
        <div class="detailsText">
          （一）接待引导志愿者 　<br />　
          负责引导观众文明参观，并填写调查问卷，协助观众了解手机预约参观流程等。<br />
          　　（二）讲解志愿者 　<br />
          　负责协助本馆讲解员开展讲解服务工作，包括：顺义历史讲解、重点文物讲解等；负责指定展厅及馆内外教育项目讲解咨询服务。
          　　<br />（三）宣传推广志愿者 　<br />
          　负责对馆内文创区“顺意好礼”展示厅的咨询服务；负责对本馆已开展或将开展各项活动的宣传工作。
          　　<br />（四）秩序维护 <br />　　
          负责引导参观人员有序进入博物馆，负责场馆门口和展厅内的秩序维护；<br />
        </div>
      </div>

      <div class="titleImgRed">
        <span class="img">
          <img src="../assets/header.png" alt="" class="miao" />
          招募基本要求
        </span>
      </div>
      <div class="detailsText">
        （一）热心公益事业，具有奉献精神，能够长期坚持为博物馆提供志愿服务，自觉遵守顺义博物馆的各项规章制度。
        　　<br />
        （二）身心健康，宽厚随和，乐观向上，有较强的责任心，具备较好的语言表达能力及沟通能力，能够熟练使用普通话。
        　　<br />
        　　（三）在北京最好长期在顺义居住、工作或学习，志愿者年龄在8至60岁之间。
        　　<br />
        　　（四）成为顺义博物馆志愿者后，需按要求预约来馆服务，且保证全年服务时长。
        　　<br />
        　　（五）针对不同服务类型，具有相关专业知识背景、经验技能，或有志愿服务工作经历者优先。
        　　<br />
        　　（六）以实际报名情况择优录取。
      </div>
      <div class="titleImgRed">
        <span class="img">
          <img src="../assets/header.png" alt="" class="miao" />
          注意事项
        </span>
      </div>
      <div class="detailsText">
                 （一）志愿者需服从馆内安排，准时到达指定岗位。 <br>
　　（二）志愿者在服务期间严禁出现聚众聊天、吸烟、饮酒等行为。<br>
　　（三）禁止携带打火机、管制刀具等物品进入场馆。<br>
　　（四）志愿者进入博物馆后注意行为礼貌，自觉维护顺义博物馆和志愿者良好形象。

      </div>
      <div class="titleImgRed">
        <span class="img">
          <img src="../assets/header.png" alt="" class="miao" />
          报名流程、时间及方式
        </span>
      </div>
      <div class="detailsText">
            电话预约--现场报名--面试--岗前培训--成为顺义博物馆志愿者； <br>
            报名时间：每周周二至周五10:00-16:00<br>
            报名方式：电话咨询、现场报名。<br>
            研学部联系电话：010-81475286转3。
      </div>
      <div class="titleImgRed">
        <span class="img">
          <img src="../assets/header.png" alt="" class="miao" />
          报名提交
        </span>
      </div>
      <div class="detailsText">
           顺义博物馆总服务台领取“志愿者报名表”纸质版，同时需携带本人身份证复印件一张，一寸免冠照片两张。
           （志愿服务类型只可选择一种 进行报名，不可多选。）
           报名表请于2021年4月30日（含）前以纸质形式交至研学部。顺义博物馆电话通知进入面试环节的人员名单；
      </div>
      <div class="titleImgRed">
        <span class="img">
          <img src="../assets/header.png" alt="" class="miao" />
          岗前培训
        </span>
      </div>
      <div class="detailsText">
        顺义博物馆后期确定招募人员后，进行集中培训（以周末为主），培训完成后
            等待电话通知上岗时间。
      </div>
        <Share :titles="title" :images="img"></Share>
    </div>
        <Footer></Footer>
  </div>
</template>

<script>
import Footer from '../components/footer'
import Share from '../components/share.vue';
import { fetch } from '../api/https';
import mainBox from "@/components/main.vue";

export default {
  data() {
    return {
      title:'志愿者报名',
      img:'',
      Scales:false
    };
  },
  components:{
      Footer,
    Share,
    mainBox
  },
  created() {},
  mounted() {
    fetch('/getmenu').then((res) => {
        this.img = res.data[7].child[1].menu_img
              this.banners = res.data[6].child[1].menu_img;
              document.title = res.data[7].child[1].menu_seo_title
    }).catch((err) => {

    });
        var width = document.documentElement.clientWidth;
        var height = document.documentElement.clientHeight;
        if(width>1024 &&  width< 1400){
          this.Scales = true
        }
  },
  methods: {

  },
};
</script>

<style scoped>
.all {
  background: rgb(249, 249, 249);
  width: 100%;
  height: auto;
}
.header {
  width: 100%;
  height: 300px;
}
.header img {
  height: 100%;
  width: 100%;
}
/* .action {
  display: block;
  font-size: 16px;
  color: #1a1a1a;
font-size: 16px;
font-family: PingFangSC;
color: #1A1A1A;
margin-top: 36px;
margin-bottom: 78px;
margin-left: 60px;
font-weight: 500;
} */
.details {
  max-width: 1400px;
  margin: 0 auto;
  height: auto;
  /* padding-bottom: 20px; */
  margin-bottom: 40px;
}

.titles {
  max-width: 1400px;
  text-align: center;
  font-size: 24px;
  color: #333333;
  line-height: 40px;
  font-weight: bold;
  margin-bottom: 29px;
  margin-top: 26px;
height: 24px;
font-size: 24px;
font-family: Source Han Sans CN;
line-height: 40px;

}
.DetailFont {
  text-indent: 2em;
  width: 1300px;
  margin: 0 auto;
  font-size: 18px;
  color: #666666;
  line-height: 30px;
  padding-bottom: 20px;
  /* padding-left: 20px; */
  font-family: SourceHanSansCN-Regular;
  font-weight: 400;
}
.DetailImg {
  height: 266px;
  width: 1300px;
  margin: 0 auto;
}
.titleImgRed img {
  float: left;
  width: 23px;
  height: 22px;
  margin-right: 10px;
  margin-top: 3px;
}
.titleImgRed {
  height: auto;
  width: 1300px;
  margin: 0 auto;
  font-size: 22px;
  /* margin-bottom: 20px; */
  margin-top: 44px;
  margin-bottom: 40px;
  color: #333333;
  font-weight: 550;
}
.detailsText {
  /* width: 1100px; */
  margin-left: 157px;
  line-height: 30px;
  text-align: left;
  color:#666666;
  font-size: 18px;
  font-weight: 400;
}
.liebiao{
    width: 100%;
}
.foots{
    text-align: right;
    width: 1200px;
    font-size: 20px;
    margin: 0 auto;
    padding-top: 47px;
    padding-bottom: 117px;
}
.foots img{
    height: 30px;
    width: 30px;
    margin-left: 10px;
    float: right;
    /* margin-top: -25px; */
}
.pop-toast {
  position: fixed;
  width: 5rem;
  padding: 0.2rem;
  text-align: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  border: 1px solid #ccc;
  background: rgba(0, 0, 0, 0.6);
  -webkit-box-shadow: 0 2px 8px #ccc;
  -moz-box-shadow: 0 2px 8px #ccc;
  box-shadow: 0 2px 8px #ccc;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  z-index: 1000000;
}

.pop-toast .toast-tip {
  font-size: 16px;
  color: #fff;
}
@media screen  and (max-width: 1440px){
  .details{
    width: 1266px;
  }
  .DetailFont{
    width: 1266px;

  }
  .DetailImg img{
    width: 1266px;
  }
  .titleImgRed{
    width: 1266px;
  }

}
</style>
